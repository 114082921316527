import React, { useState, useEffect } from "react";

function ChatMessageUser(props) {
  const [dataSender, setDataSender] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://sph-v2-backend-22066.nodechef.com/api/users?id=${props.senderId}`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization:
                "Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU",
            },
          }
        );
        const data = await response.json();
        console.log("ChatMessageUser data", data);
        setDataSender(data.data.firstName + " " + data.data.lastName);
      } catch (error) {
        console.error(error);
        setDataSender("");
      }
    };

    fetchData();
  }, [props.senderId]);

  return dataSender || "";
}

export default ChatMessageUser;
