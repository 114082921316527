// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
} from 'firebase/auth';
import { getFirestore, query, getDoc, getDocs, collection, where, addDoc, updateDoc, doc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, getMetadata, getBlob } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyAzzIM6WGt7IB3akF9kwcxtBzj5PDrQDAI',
	authDomain: 'smartplanthomev2.firebaseapp.com',
	projectId: 'smartplanthomev2',
	storageBucket: 'smartplanthomev2.appspot.com',
	messagingSenderId: '60522140729',
	appId: '1:60522140729:web:fa18671a7c8a3ad9a229e8',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage();

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
	try {
		const res = await signInWithPopup(auth, googleProvider);
		const user = res.user;
		const q = query(collection(db, 'users'), where('uid', '==', user.uid));
		const docs = await getDocs(q);
		if (docs.docs.length === 0) {
			await addDoc(collection(db, 'users'), {
				uid: user.uid,
				name: user.displayName,
				authProvider: 'google',
				email: user.email,
			});
		}
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const logInWithEmailAndPassword = async (email, password) => {
	try {
		const user = await signInWithEmailAndPassword(auth, email, password);
		console.log({ user });
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const registerWithEmailAndPassword = async (name, email, password) => {
	try {
		const res = await createUserWithEmailAndPassword(auth, email, password);
		const user = res.user;
		await addDoc(collection(db, 'users'), {
			uid: user.uid,
			name,
			authProvider: 'local',
			email,
		});
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const sendPasswordReset = async (email) => {
	try {
		await sendPasswordResetEmail(auth, email);
		alert('Password reset link sent!');
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const logout = () => {
	signOut(auth);
};

const getUser = async (docID) => {
	try {
		const usersCollectionRef = query(collection(db, 'users'), where('uid', '==', docID));
		const querySnapshot = await getDocs(usersCollectionRef);

		if (querySnapshot.empty) {
			throw new Error('User document not found!');
		}

		// Get the document reference for the first matching document
		const userDocRef = doc(collection(db, 'users'), querySnapshot.docs[0].id);

		// Get the document snapshot using the user document reference
		const docSnapshot = await getDoc(userDocRef);

		if (!docSnapshot.exists()) {
			throw new Error('User document not found!');
		}

		// Extract the data from the document snapshot
		const userData = docSnapshot.data();
		return userData;
	} catch (error) {
		console.error('Error fetching user data:', error);
		throw error;
	}
};

// ! Under construction (maybe completed)
const uploadImage = async (file, path) => {
	try {
		const storageRef = ref(storage, path);
		// await storageRef.put(file);
		const uploadedBytes = await uploadBytes(storageRef, file);
		console.log({ uploadedBytes });
		const url = await getDownloadURL(storageRef);
		return url;
	} catch (error) {
		console.error('Error uploading image:', error);
		throw error;
	}
};

export {
	auth,
	db,
	signInWithGoogle,
	logInWithEmailAndPassword,
	registerWithEmailAndPassword,
	sendPasswordReset,
	logout,
	getUser,
	uploadImage,
};
