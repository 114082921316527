import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword, signInWithGoogle } from "../firebase";
import "./Register.css";

function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();

  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);

  return (
    <div className="login register__bg">
      <div className="register__wrap">
        <h1 className="register__title">Create your free account</h1>
        <div className="register__form">
          
          <div className="register__form__field">
            <label>Full Name</label>
            <input type="text" className="register__textBox" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" />
          </div>

          <div className="register__form__field">
            <label>EMAIL</label>
            <input type="text" className="register__textBox" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="example@mail.com" />
          </div>

          <div className="register__form__field">
            <label>PASSWORD</label>
            <input type="password" className="register__textBox" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="PASSWORD" />
          </div>

          <div className="register__form__field">
            <button className="register__btn" onClick={register}>Register</button>
            <button className="register__btn register__google" onClick={signInWithGoogle}>Register with Google</button>
          </div>

          <div className="register__form__field">
            <div className="register__form__field__login">Already have account? <Link to="/">Login</Link></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Register;
