import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { auth, getUser, uploadImage } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

// import single_image from './../assets/img/single_image.jpg';
import image_placeholder from './../assets/img/placeholder.jpg';

import Header from '../inc/Header';
import Banner from '../inc/Banner';
import Footer from '../inc/Footer';
import ChatMessageUser from '../inc/ChatMessageUser';
import SelectCategory from '../inc/SelectCategory';
import SelectPlant from '../inc/SelectPlant';
import PlantDetailAccordion from '../Components/PlantDetailAccordion';

import debounce from 'lodash.debounce';

function Single() {
	const [firebase_user] = useAuthState(auth);
	const { userID, id } = useParams();

	const location = useLocation();
	// const { plant } = location.state || {};
	// const [plant, setPlant] = useState(location.state?.plant || {});
	const [plant, setPlant] = useState({});
	const [plantDetail, setPlantDetail] = useState({});

	const [user, setUser] = useState({});
	const [chat, setChat] = useState([]);
	const [expertUser, setExpertUser] = useState([]);

	const [gardenCategory, setGardenCategory] = useState('');
	const [gardenSearch, setGardenSearch] = useState('');
	const [loadingSelectPlant, setLoadingSelectPlant] = useState(false);

	const [isUserReady, setIsUserReady] = useState(false);
	const [otherUser, setOtherUser] = useState({ firstName: '', lastName: '' }); // Other user is the user who is not the current user

	const [singleSearchPlant, setSingleSearchPlant] = useState('');
	const [singleSearchPlantJson, setSingleSearchPlantJson] = useState([]);
	const [loadingSearchPlant, setLoadingSearchPlant] = useState(false);
	const [singleSearchPlantDropdown, setSingleSearchPlantDropdown] = useState('');

	useEffect(() => console.log({ otherUser }), [otherUser]);

	const navigate = useNavigate();

	function handleGardenCategory() {
		setLoadingSelectPlant('');
		setTimeout(() => {
			const categorySelect = document.getElementById('single_garden_category');
			const selectedCategory = categorySelect.options[categorySelect.selectedIndex].value;
			setGardenCategory(selectedCategory);
			setLoadingSelectPlant(selectedCategory);
		}, 500);
	}

	const handleGardenSearch = (e) => {
		// setLoadingSelectPlant(false);
		setGardenSearch(e.target.value);
		// setLoadingSelectPlant(true);
		setLoadingSelectPlant(gardenCategory && e.target.value);
		// (gardenSearch && gardenCategory) ? setLoadingSelectPlant(true) : setLoadingSelectPlant(false);
	};

	useEffect(() => {
		// Get User Firebase
		if (firebase_user) {
			setUser(firebase_user.email);
			setIsUserReady(true); // Set isUserReady to true when user is available
		}
	}, [firebase_user]);

	useEffect(() => {
		const fetchData = async () => {
			if (isUserReady) {
				try {
					const response = await fetch(`https://sph-v2-backend-22066.nodechef.com/api/users?email=${user}`, {
						method: 'GET',
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
							Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
						},
					});
					const data = await response.json();
					let expertUserDetail = data.success === true ? data.data : {};
					setExpertUser(expertUserDetail);
				} catch (error) {
					console.error('Chat Detail API', error);
				}
			}
		};

		fetchData();
	}, [isUserReady, user, firebase_user]);

	const handleSingleGardenMarkAsComplete = async (e) => {
		if (plant._id) {
			await fetch('https://sph-v2-backend-22066.nodechef.com/api/expertChangeChatStatus', {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
				},
				body: JSON.stringify({
					chatMasterId: plant._id,
					status: 'Resolved',
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					navigate('/');
				})
				.catch((error) => console.error(error));
		}
	};
	// let controller;
	const [controller, setController] = useState(null);
	const handleSingleSearchPlant = async (e) => {
		const value = e.target.value;
		if (value === singleSearchPlant) return;
		setSingleSearchPlant(value);
		if (value === '' || !value) return setSingleSearchPlantJson([]);
		console.log('Searching for: ', value);
		try {
			// console.log({ controller });
			if (controller) {
				console.log('aborting');
				controller.abort();
			}
			const controllerr = new AbortController();
			setController(controllerr);
			console.log('after: ', { controller });
			const url = `https://sph-v2-backend-22066.nodechef.com/api/searchPlant?type=Search&name=${value}&limit=50`;
			setLoadingSearchPlant(true);
			const res = await fetch(url, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
				},
				signal: controllerr.signal,
			});
			const data = await res.json();
			const plants = data.data;
			// if (value !== singleSearchPlant) return; // This means that a more recent search has been made
			setSingleSearchPlantJson(plants);
		} catch (error) {
			console.error('handleSearch error', error);
		} finally {
			setLoadingSearchPlant(false);
		}
	};

	// const debouncedPlants = useMemo(() => {
	// 	return debounce(handleSingleSearchPlant, 300);
	// }, []);
	const debouncedPlants = debounce(handleSingleSearchPlant, 300);

	const handleAssign = async () => {
		if (!plant._id || !singleSearchPlantDropdown) return;

		try {
			const res = await fetch('https://sph-v2-backend-22066.nodechef.com/api/expertIdentifyPlant', {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
				},
				body: JSON.stringify({
					chatMasterId: plant._id,
					plantId: singleSearchPlantDropdown,
				}),
			});

			const res2 = await fetch('https://sph-v2-backend-22066.nodechef.com/api/expertChangeChatStatus', {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
				},
				body: JSON.stringify({
					chatMasterId: plant._id,
					status: 'Resolved',
				}),
			});
			const currentPlantDetail = await getPlantDetail(singleSearchPlantDropdown);
			console.log({ currentPlantDetail });
			// const { _id, commonName, image, latinName, plantDescription } = await res2.json();
			setPlantDetail(currentPlantDetail);

			setPlant({
				...plant,
				status: 'Resolved',
			});
		} catch (error) {
			console.error(error);
			alert('Error in assigning plant. Please try again');
		}
	};

	// Get user information given his ID
	const getUserInfo = async (userId) => {
		console.log('Get user info called with userid: ', userId);
		try {
			const res = await fetch(`https://sph-v2-backend-22066.nodechef.com/api/users?id=${userId}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
				},
			});
			const data = await res.json();
			if (data.success)
				setOtherUser({
					firstName: data.data.firstName,
					lastName: data.data.lastName,
					zipCode: data?.data?.zipcode,
					email: data.data.email,
				});
			else setOtherUser({});
		} catch (error) {
			console.error({ error });
			// alert("Error in fetching messages");
		}
	};

	// Get plant details given its ID
	const getPlantDetail = async (plantId) => {
		setPlantDetail({});
		try {
			const res = await fetch(`https://sph-v2-backend-22066.nodechef.com/api/plant/${plantId}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
				},
			});
			const data = await res.json();
			return data?.data || {};
		} catch (error) {
			console.error({ error });
			return {};
		}
	};

	const handleSingleGardenMarkAsPending = async (e) => {
		if (plant._id) {
			await fetch('https://sph-v2-backend-22066.nodechef.com/api/expertChangeChatStatus', {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
				},
				body: JSON.stringify({
					chatMasterId: plant._id,
					status: 'Pending',
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					setPlant({
						...plant,
						status: 'Pending',
					});
				})
				.catch((error) => console.error(error));
		}
	};

	const getChatDetails = async (userID, chat_id) => {
		try {
			const res = await fetch(`https://sph-v2-backend-22066.nodechef.com/api/chat?userId=${userID}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
				},
			});
			const allChats = await res.json();
			const currentChat = allChats.data.find((chat) => chat._id === chat_id);
			return currentChat;
		} catch (error) {
			console.error({ error });
			return {};
		}
	};

	const mountingCalls = async (chat_id, userID) => {
		if (!id) return;
		const currentChat = await getChatDetails(userID, chat_id);
		if (currentChat.status !== 'Pending') {
			console.log('currentChat.status', currentChat.status);
			const plant = await getPlantDetail(currentChat?.plant_id?._id || '');
			setPlantDetail(plant);
			setChat(plant._id);
		}
		setPlant(currentChat);
		getUserInfo(userID);
	};

	useEffect(() => {
		if (!id || !userID) return;
		mountingCalls(id, userID);

		return () => {
			debouncedPlants.cancel();
		};
	}, []);

	return (
		<>
			<Header />
			<Banner type='chat' plant={plant} />

			<div className='container mt-4'>
				<div className='row g-5 mb-5 single_garden'>
					{plant.status == 'Pending' && plant.type == 1 && (
						<MarkCompletedButton handleSingleGardenMarkAsComplete={handleSingleGardenMarkAsComplete} />
					)}

					{/* All pending plants that are not of type 1  */}
					{plant.status === 'Pending' && (
						<div className='col-6'>
							<div className='single_garden--image'>
								<img
									src={plant.image && plant.image !== 'string' ? plant.image : image_placeholder}
									alt={plant.title}
								/>
							</div>
						</div>
					)}

					{/* Pending plants of type 1 */}
					{/* {plant.type === 1 && plant.status === 'Pending' && (
						<div className='col-6'>
							<div className='single_garden--image'>
								<img
									src={plant.image && plant.image !== 'string' ? plant.image : image_placeholder}
									alt={plant.title}
								/>
							</div>
						</div>
					)} */}
					{/* Pending plants of type 2 */}
					{plant.type === 2 && plant.status === 'Pending' && (
						<div className='col-6'>
							<div className='single_garden'>
								<div className='single_garden--form'>
									<div className='single_garden--subtitle'>Select a Plant</div>

									<SelectCategory onChange={handleGardenCategory} />

									<input type='hidden' id='single_garden_plant_result' value='' />

									{loadingSelectPlant && (
										<SelectPlant
											gardenCategory={gardenCategory}
											setSingleSearchPlantDropdown={setSingleSearchPlantDropdown}
										/>
									)}

									<div className='single_garden--subtitle'>Or Search Plant</div>

									<div
										className='search-plant-main single_garden--select
                  single_garden--select'>
										<input
											type='text'
											placeholder='Search Plant...'
											// onChange={handleSingleSearchPlant}
											// value={singleSearchPlant}
											onChange={debouncedPlants}
											// onChange={wrapper}
										/>
										{/* <button className='plant-search'>Search</button> */}
									</div>

									{/* {!loadingSearchPlant &&  > 0 && ( */}
									<div className='single_garden--select'>
										<select
											onChange={(e) => {
												const value = e.target.value;
												if (!value || value === '') return;
												setSingleSearchPlantDropdown(e.target.value);
											}}>
											<option key='' value=''>
												{loadingSearchPlant && 'Loading...'}
												{!loadingSearchPlant &&
													singleSearchPlantJson.length === 0 &&
													'No plant found'}
												{!loadingSearchPlant &&
													singleSearchPlantJson.length > 0 &&
													'Select a Plant'}
											</option>

											{!loadingSearchPlant &&
												// singleSearchPlantJson.length &&
												singleSearchPlantJson.map((p) => (
													<option key={p._id} value={p._id}>
														{p.commonName ? p.commonName : p.latinName}
													</option>
												))}
										</select>
									</div>
									{/* )} */}
								</div>
								<hr />
								{singleSearchPlantDropdown && (
									<PlantDetailAccordion
										plantID={singleSearchPlantDropdown}
										handleAssign={handleAssign}
										chat_id={id}
										sender_id={expertUser._id}
									/>
								)}
							</div>
						</div>
					)}

					{/* Resolved Plants of all types but 1 */}
					{plant.status === 'Resolved' && plant.type !== 1 && (
						<PlantDetailShower
							plant={plant}
							plantDetail={plantDetail}
							handleSingleGardenMarkAsPending={handleSingleGardenMarkAsPending}
						/>
					)}
					{plant.status === 'Resolved' && plant.type == 1 && (
						<button
							onClick={(e) => handleSingleGardenMarkAsPending(e)}
							className='single_garden--button single_garden--button--assign'>
							Mark as Pending
						</button>
					)}
				</div>

				<div className='my-5 d-flex justify-content-evenly align-items-center'>
					<div>
						<h6 style={{ fontSize: '1.5rem', fontWeight: '600', textDecoration: 'underline' }}>
							User Location
						</h6>
						<h6 style={{ fontSize: 'rem', fontWeight: '500' }} className='text-start'>
							Name: {otherUser.firstName} {otherUser.lastName}
						</h6>
						<h6 style={{ fontSize: 'rem', fontWeight: '500' }} className='text-start'>
							Email: {otherUser.email}
						</h6>
					</div>
					<Map zipCode={otherUser.zipCode} />
				</div>

				<MessageCenter id={id} plant={plant} expertUser={expertUser} otherUser={otherUser} />
			</div>
			<Footer />
		</>
	);
}

const MessageLoader = ({ messageImage }) => {
	const profileImage =
		'https://graph.facebook.com/1117856601605546/picture?width=100&height=100&return_ssl_resources=1';
	return (
		<div className='single_garden_messages_center--response'>
			<div className='single_garden_messages_center--reply_box'>
				<img src={profileImage} alt={'Img'} />
				<div className='single_garden_messages_center--message'>
					<div className='message-loader'></div>
					{/* {chatDetailData.messages
            ? chatDetailData.messages
            : ""} */}
				</div>
			</div>
			{messageImage && (
				<div className='w-full d-flex justify-content-center my-2'>
					<div className='message-loader w-25' style={{ height: '10rem' }} />
				</div>
			)}
			<div className='single_garden_messages_center--message--stamp'>
				<div className='message-loader w-25' />
			</div>
		</div>
	);
};

const Map = ({ zipCode }) => {
	const [address, setAddress] = useState('');
	const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
	// const apiKey = 'AIzaSyAyiNrJQlqqzC5ey0urm4PzL1TZ1ifeVZg';
	const apiKey = 'AIzaSyCyH553Tlw6eevrIbjwVXwBJGOZwW9RQbs';

	const fetchAddress = async () => {
		const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${apiKey}`;

		try {
			const response = await fetch(apiUrl);
			const data = await response.json();
			if (data.results.length > 0) {
				const formattedAddress = data.results[0].formatted_address;
				setAddress(formattedAddress);
				const { lat, lng } = data.results[0].geometry.location;
				setMapCenter({ lat, lng });
			} else {
				setAddress('Address not found');
			}
		} catch (error) {
			console.error('Error fetching address:', error);
			setAddress('Error fetching address');
		}
	};

	useEffect(() => {
		fetchAddress();
	}, [zipCode]);

	return (
		<div className='d-flex flex-column align-items-center justify-content-center'>
			<p>📍 {address}</p>
			<div style={{ height: '10rem', width: '10rem' }}>
				<LoadScript googleMapsApiKey={apiKey}>
					<GoogleMap mapContainerStyle={{ height: '100%', width: '100%' }} center={mapCenter} zoom={10}>
						{address && <Marker position={mapCenter} />}
					</GoogleMap>
				</LoadScript>
			</div>
		</div>
	);
};

function timeElapsed(date) {
	const seconds = Math.floor((new Date() - date) / 1000); // Calculate time elapsed in seconds
	let interval = Math.floor(seconds / 31536000);

	if (interval >= 1) {
		return `${interval} year${interval === 1 ? '' : 's'} ago`;
	}
	interval = Math.floor(seconds / 2592000);
	if (interval >= 1) {
		return `${interval} month${interval === 1 ? '' : 's'} ago`;
	}
	interval = Math.floor(seconds / 86400);
	if (interval >= 1) {
		return `${interval} day${interval === 1 ? '' : 's'} ago`;
	}
	interval = Math.floor(seconds / 3600);
	if (interval >= 1) {
		return `${interval} hour${interval === 1 ? '' : 's'} ago`;
	}
	interval = Math.floor(seconds / 60);
	if (interval >= 1) {
		return `${interval} minute${interval === 1 ? '' : 's'} ago`;
	}
	return `${Math.floor(seconds)} second${Math.floor(seconds) === 1 ? '' : 's'} ago`;
}

const MarkCompletedButton = ({ handleSingleGardenMarkAsComplete }) => (
	<div className='col-6'>
		<div className='single_garden'>
			<div className='single_garden--form'>
				<div className='single_garden--buttons'>
					<button
						onClick={(e) => handleSingleGardenMarkAsComplete(e)}
						className='single_garden--button single_garden--button--assign'>
						Mark as Completed
					</button>
				</div>
			</div>
		</div>
	</div>
);

const PlantDetailShower = ({ plant, plantDetail, handleSingleGardenMarkAsPending }) => {
	const navigate = useNavigate();

	// const handleSingleGardenMarkAsPending = async (e) => {
	// 	if (plant._id) {
	// 		await fetch('https://sph-v2-backend-22066.nodechef.com/api/expertChangeChatStatus', {
	// 			method: 'PUT',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
	// 			},
	// 			body: JSON.stringify({
	// 				chatMasterId: plant._id,
	// 				status: 'Pending',
	// 			}),
	// 		})
	// 			.then((response) => response.json())
	// 			.then((data) => {
	// 				navigate('/');
	// 			})
	// 			.catch((error) => console.error(error));
	// 	}
	// };
	return (
		<div className='col-6 w-100'>
			<div className='single_garden w-100'>
				<div className='w-100 row'>
					<div className='col col-md-2'>
						<button
							onClick={(e) => handleSingleGardenMarkAsPending(e)}
							className='single_garden--button single_garden--button--assign'>
							Mark as Pending
						</button>
					</div>
					<div className='col col-md-8 '>
						<div className='single_garden--title text-center'>
							<span className='text-secondary fs-4'>Identified as </span>
							<span style={{ color: '#83b86d' }}>
								{plantDetail?.commonName || plantDetail?.latinName || ''}
							</span>
						</div>
					</div>

					<div className='col col-md-2 '>
						<img
							className='h-50 w-auto rounded'
							src={plant.image && plant.image !== 'string' ? plant.image : image_placeholder}
							alt={plant._id}
						/>
						{/* </div> */}
					</div>
				</div>
				<div className='my-8'>
					<span className='text-secondary fs-1 single_garden--title'>Plant Details</span>
					<div className='row d-flex justify-content-evenly align-items-center'>
						<figure className='figure col-6 col-md-3'>
							<img src={plantDetail?.image} className='figure-img img-fluid rounded' alt='Plant image' />
							<figcaption className='figure-caption'>
								{plantDetail?.commonName || plantDetail?.latinName || ''} Plant
							</figcaption>
						</figure>

						<ul className='list-group col-12 col-md-6'>
							<li className='list-group-item d-flex justify-content-between align-items-start text-start'>
								<div className='ms-2 me-auto'>
									<div className='fw-bold'>Common Name</div>
									{plantDetail?.commonName || 'N/A'}
								</div>
							</li>
							<li className='list-group-item d-flex justify-content-between align-items-start text-start'>
								<div className='ms-2 me-auto'>
									<div className='fw-bold'>Latin Name</div>
									{plantDetail?.latinName || 'N/A'}
								</div>
							</li>
							<li className='list-group-item d-flex justify-content-between align-items-start text-start'>
								<div className='ms-2 me-auto'>
									<div className='fw-bold'>Description</div>
									<div style={{ textAlign: 'justify' }}>{plantDetail?.plantDescription || 'N/A'}</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

const MessageCenter = ({ id, plant, expertUser, otherUser }) => {
	const [chatDetail, setChatDetail] = useState([]);
	const [singleGardenMessage, setSingleGardenMessage] = useState('');
	const [messageImage, setMessageImage] = useState('');
	const [messageSending, setMessageSending] = useState(false); // A flag to indicate if a message is being sent

	const handleSingleGardenMessage = async (e) => {
		if (!singleGardenMessage || !expertUser._id) return;
		if (messageSending) return;
		setMessageSending(true);
		let imgURL = '';
		if (messageImage) imgURL = await uploadImage(messageImage, `chat/${plant._id}/${messageImage.name}`);

		fetch('https://sph-v2-backend-22066.nodechef.com/api/chatDetails', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				// "Content-Type": 'image/jpeg',
				Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
			},
			body: JSON.stringify({
				chat_id: id,
				// chat_id: plant._id,
				sender_id: expertUser._id,
				messages: singleGardenMessage,
				// image: URL.createObjectURL(messageImage),
				image: imgURL,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				setSingleGardenMessage('');
				setMessageImage('');
				const newMsg = {
					...data.data,
					profileImage:
						'https://graph.facebook.com/1117856601605546/picture?width=100&height=100&return_ssl_resources=1',
				};
				setChatDetail([...chatDetail, newMsg]);
			})
			.catch((error) => console.error(error))
			.finally(() => setMessageSending(false));
	};

	const uploadImageLocal = async (e) => {
		const file = e.target.files[0];
		setMessageImage(file);
	};

	// A function that uses long polling to fetch messages from the server
	const subscribeToMessages = (id) => {
		const intervalId = setInterval(async () => {
			try {
				const res = await fetch(`https://sph-v2-backend-22066.nodechef.com/api/chatDetails/${id}`, {
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
					},
				});
				const data = await res.json();
				if (data.success === true) setChatDetail(data.data);
				else setChatDetail([]);
			} catch (error) {
				console.error({ error });
				// alert("Error in fetching messages");
			}
		}, 5000); // Fetch messages every 5 seconds = 5000 milliseconds
		return intervalId;
	};

	const fetchChat = async (id) => {
		try {
			const res = await fetch(`https://sph-v2-backend-22066.nodechef.com/api/chatDetails/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
				},
			});
			const data = await res.json();
			if (data.success === true) setChatDetail(data.data);
			else setChatDetail([]);
		} catch (error) {
			console.error({ error });
			// alert("Error in fetching messages");
		}
	};

	useEffect(() => {
		fetchChat(id);
		const intervalId = subscribeToMessages(id);

		return () => {
			console.log('clearing');
			clearInterval(intervalId);
		};
	}, []);

	return (
		<div className='row g-5 mb-5 single_garden_messages_center'>
			<div className='col-12'>
				<div className='single_garden--title single_garden_messages_center--title'>Messages Center</div>
			</div>

			<div className='col-6'>
				{/* H6 for gutter only */}
				<h6 style={{ fontSize: '1.5rem', fontWeight: '600', visibility: 'hidden' }}>GUTTER</h6>
				<div className='single_garden_messages_center--textarea'>
					<textarea
						rows='10'
						id='single_garden_message'
						placeholder='Enter a message to the user.'
						onChange={(e) => setSingleGardenMessage(e.target.value)}
						value={singleGardenMessage}
					/>
				</div>

				{singleGardenMessage ? (
					<div className='single_garden_messages_center--buttons'>
						<button
							onClick={(e) => handleSingleGardenMessage(e)}
							className='single_garden_messages_center--button single_garden_messages_center--button--send'>
							Send
						</button>
						<div>
							<label htmlFor='addFile'>Upload an image</label>
							<input type='file' name='addFile' id='file' onChange={uploadImageLocal} />
						</div>
					</div>
				) : (
					''
				)}
			</div>

			<div className='col-6'>
				<h6 style={{ fontSize: '1.5rem', fontWeight: '600' }}>Chat</h6>

				<div className='single_garden_messages_center--messages'>
					{chatDetail
						? chatDetail?.length > 0
							? chatDetail?.map((chatDetailData, index) => {
									if (expertUser?._id === chatDetailData?.sender_id) {
										return (
											<div key={index} className='single_garden_messages_center--reply'>
												<div className='single_garden_messages_center--reply_box'>
													<img src={chatDetailData?.profileImage} alt={'user'} />

													<div className='single_garden_messages_center--message'>
														{chatDetailData.messages ? chatDetailData.messages : ''}
													</div>
												</div>
												{chatDetailData?.image && (
													<div className='w-full d-flex justify-content-center my-2'>
														<img
															src={chatDetailData?.image}
															alt={'broken '}
															className='h-auto w-25'
														/>
													</div>
												)}
												<div className='single_garden_messages_center--message--stamp'>
													{chatDetailData.updatedAt
														? timeElapsed(new Date(chatDetailData.updatedAt))
														: ''}{' '}
													{expertUser?.firstName} {expertUser?.lastName}
												</div>
											</div>
										);
									} else {
										return (
											<div key={index} className='single_garden_messages_center--response'>
												<div className='single_garden_messages_center--reply_box'>
													<img
														src={chatDetailData?.profileImage}
														alt={expertUser?.firstName}
													/>
													<div className='single_garden_messages_center--message'>
														{chatDetailData.messages ? chatDetailData.messages : ''}
													</div>
												</div>
												{chatDetailData?.image && (
													<div className='w-full d-flex justify-content-center my-2'>
														<img
															src={chatDetailData?.image}
															alt={'broken'}
															className='h-auto w-25'
														/>
													</div>
												)}
												<div className='single_garden_messages_center--message--stamp'>
													{chatDetailData.updatedAt
														? timeElapsed(new Date(chatDetailData.updatedAt))
														: ''}{' '}
													{otherUser?.firstName} {otherUser?.lastName}
												</div>
											</div>
										);
									}
							  })
							: 'No messages have been sent yet.'
						: ''}
					{messageSending && <MessageLoader messageImage={messageImage} />}
				</div>
			</div>
		</div>
	);
};

export default Single;
