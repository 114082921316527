function Footer() {
  return (
    <footer className="main_footer">
        <div className="container">
          <div className="row">
            <div className="col-12">All Rights Reserved by Smart Plant & Tree Care App</div>
          </div>
        </div>
    </footer>
  );
}

export default Footer;
