import React, { Component } from "react";

class SelectCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: true,
    };
  }

  componentDidMount() {
    fetch(
      "https://sph-v2-backend-22066.nodechef.com/api/searchPlant?type=category&limit=50",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("categories", data.data);
        this.setState({
          categories: data.data,
          loading: false,
        });
      })
      .catch((error) => {
        // console.log(error);
        this.setState({ loading: false });
      });
  }

  handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    this.setState({ selectedCategory });
    this.props.onChange(selectedCategory);
  };

  render() {
    const { categories, loading } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    // console.log("categories", categories);

    return (
      <div className="single_garden--select">
        <select
          id="single_garden_category"
          onChange={this.handleCategoryChange}
        >
          <option key="" value="">
            Select a Category
          </option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default SelectCategory;
