import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const PlantDetailAccordion = ({ chat_id, sender_id, plantID, handleAssign }) => {
	const [show, setShow] = useState(false);
	const [searchPlantDetail, setSearchPlantDetail] = useState({});
	const [searchPlantLoading, setSearchPlantLoading] = useState(false);
	const [message, setMessage] = useState('');

	const sendMessage = async () => {
		if (!chat_id || !message || !sender_id) return;
		const res = fetch('https://sph-v2-backend-22066.nodechef.com/api/chatDetails', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
			},
			body: JSON.stringify({
				chat_id,
				sender_id,
				messages: message,
			}),
		});
	};

	const handleAssignClick = async () => {
        await sendMessage();
		await handleAssign();
		setShow(false);
	};

	// Get plant details given its ID
	const getPlantDetail = async (plantId) => {
		setSearchPlantLoading(true);
		setSearchPlantDetail({});
		try {
			const res = await fetch(`https://sph-v2-backend-22066.nodechef.com/api/plant/${plantId}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
				},
			});
			const data = await res.json();
			setSearchPlantDetail(data?.data || {});
			setSearchPlantLoading(false);
		} catch (error) {
			console.error({ error });
		}
	};

	useEffect(() => {
		getPlantDetail(plantID);
	}, [plantID]);

	return (
		<>
			<div className='accordion my-4' id='plantInfo'>
				<div className='accordion-item'>
					<h2 className='accordion-header d-flex'>
						<button
							className='accordion-button'
							type='button'
							data-bs-toggle='collapse'
							data-bs-target='#collapseOne'
							aria-expanded='true'
							aria-controls='collapseOne'>
							<div>
								{searchPlantLoading && (
									<div className='spinner-border' role='status'>
										<span className='visually-hidden'>Loading...</span>
									</div>
								)}
								{(!searchPlantLoading && searchPlantDetail?.commonName) ||
									searchPlantDetail?.latinName ||
									'Plant Details'}
							</div>
						</button>
						{!searchPlantLoading && (
							<div className='single_garden--buttons'>
								<button
									onClick={() => setShow(true)}
									className='single_garden--button single_garden--button--assign'>
									Assign
								</button>
							</div>
						)}
					</h2>
					<div className='mt-4 mx-4'>
						<input
							type='text'
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							placeholder='Enter your message here'
						/>
					</div>
					<div id='collapseOne' className='accordion-collapse collapse show' data-bs-parent='#plantInfo'>
						<div className='accordion-body'>
							<ul className='list-group'>
								<li className='list-group-item d-flex justify-content-between align-items-start text-start'>
									<div className='ms-2 me-auto'>
										<div className='fw-bold'>Common Name</div>
										{searchPlantLoading && (
											<div className='spinner-border' role='status'>
												<span className='visually-hidden'>Loading...</span>
											</div>
										)}
										{(!searchPlantLoading && searchPlantDetail?.commonName) || ''}
									</div>
								</li>
								<li className='list-group-item d-flex justify-content-between align-items-start text-start'>
									<div className='ms-2 me-auto'>
										<div className='fw-bold'>Latin Name</div>
										{searchPlantLoading && (
											<div className='spinner-border' role='status'>
												<span className='visually-hidden'>Loading...</span>
											</div>
										)}
										{(!searchPlantLoading && searchPlantDetail?.latinName) || ''}
									</div>
								</li>
								<li className='list-group-item d-flex justify-content-between align-items-start text-start'>
									<div className='ms-2 me-auto'>
										<div className='fw-bold'>Description</div>
										<div style={{ textAlign: 'justify' }}>
											{searchPlantLoading && (
												<div className='spinner-border' role='status'>
													<span className='visually-hidden'>Loading...</span>
												</div>
											)}
											{(!searchPlantLoading && searchPlantDetail?.plantDescription) || ''}
										</div>
									</div>
								</li>
								<li className='list-group-item d-flex justify-content-between align-items-start text-start'>
									<figure className='figure col-6 col-md-3'>
										<img
											src={searchPlantDetail?.image}
											className='figure-img img-fluid rounded'
											alt='Plant image'
										/>
										<figcaption className='figure-caption'>
											{searchPlantDetail?.commonName || searchPlantDetail?.latinName || ''} Plant
										</figcaption>
									</figure>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<ConfirmationModal
				show={show}
				setShow={setShow}
				handleAssign={handleAssignClick}
				plantName={searchPlantDetail?.commonName || searchPlantDetail?.latinName || ''}
			/>
		</>
	);
};

const ConfirmationModal = ({ show, setShow, handleAssign, plantName }) => {
	const [disabled, setDisabled] = useState(false);
	return (
		<Modal show={show} onHide={() => setShow(false)} aria-labelledby='contained-modal-title-vcenter' centered>
			<Modal.Header closeButton>
				<Modal.Title>Confirmation</Modal.Title>
			</Modal.Header>
			<Modal.Body>Are you sure you want to assign this plant as {plantName}?</Modal.Body>
			<Modal.Footer>
				<button className='btn btn-secondary' style={{ width: 'auto' }} onClick={() => setShow(false)}>
					Close
				</button>
				<button
					className='btn btn-success single_garden--button single_garden--button--assign'
					style={{ width: 'auto', color: '#fff' }}
					onClick={() => {
						setDisabled(true);
						handleAssign();
					}}
					disabled={disabled}>
					{disabled && <div className='spinner-border spinner-border-sm' role='status'></div>}
					{!disabled && 'Yes'}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default PlantDetailAccordion;
