import React from 'react';
import logo from './../assets/img/logo.png';
import { useNavigate, Link } from "react-router-dom";
import { logout } from "../firebase";

function Header() {

    const navigate = useNavigate();

    const logout_btn = () => {
        logout();
        setTimeout(() => {
            navigate("/login");
        }, 500);
    };

    return (
    <header className="main_header">
        <div className="container">
            <div className="row g-5">
            <div className="col-auto">
                <Link to="/">
                    <img className="main_header--logo" src={logo} alt="Logo" />
                </Link>
            </div>
            <div className="col">
                <nav className="main_header--nav">
                <ul>
                    <li><Link className="main_header--link" to="/">Home</Link></li>
                    {/*
                    <li><Link className="main_header--link" to="/single">Profile</Link></li>
                    */}
                    <li><Link className="main_header--link" to="#Logout" onClick={logout_btn}>Logout</Link></li>
                </ul>
                </nav>
            </div>
            </div>
        </div>
    </header>
  );
}

export default Header;
