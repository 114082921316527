import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Single from './pages/Single';
import Login from './pages/Login';
import Register from './pages/Register';
import Reset from './pages/Reset';
import './App.css';

function App() {
	return (
		<div className='App'>
			<Routes>
				<Route exact index path='/' element={<Login />} />
				<Route exact path='/login' element={<Login />} />
				<Route exact path='/register' element={<Register />} />
				<Route exact path='/reset' element={<Reset />} />
				<Route exact path='/dashboard' Component={Home} />
				<Route path='/single/:userID/:id' Component={Single} />
			</Routes>
		</div>
	);
}

export default App;
