import React, { Component } from 'react';

function handleSingleGardenPlant(e, setSingleSearchPlantDropdown, plants) {
	if (!plants) return;

	const val = e.target.value;
	const element = document.getElementById('single_garden_plant_result');
	// console.log('handleSingleGardenPlant val', val);
	element.value = val;
	for (let i = 0; i < plants.length; i++) {
		const element = plants[i];
		if (element.commonName === val || element.latinName === val) {
			// console.log({ element });
			setSingleSearchPlantDropdown(element._id);
			break;
		}
	}
	// const event = new Event('change');
	// element.dispatchEvent(event);
}

class SelectPlant extends Component {
	constructor(props) {
		super(props);
		this.state = {
			plants: [],
			loading: true,
		};
	}

	componentDidMount() {
		const { gardenCategory, gardenSearch } = this.props;
		// fetch("https://sph-v2-backend-22066.nodechef.com/api/searchPlant?type=category&categoryId=Y0nTsz9NKV&name=a&limit=10")
		// fetch( `https://sph-v2-backend-22066.nodechef.com/api/searchPlant?type=Search&categoryId=${gardenCategory}&name=${gardenSearch}&limit=10`,

		fetch(`https://sph-v2-backend-22066.nodechef.com/api/${gardenCategory}/plant?limit=100`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				this.setState({
					plants: data.data,
					loading: false,
				});
			})
			.catch((error) => {
				// console.log(error);
				this.setState({ loading: false });
			});
	}

	render() {
		const { gardenCategory, gardenSearch, setSingleSearchPlantDropdown } = this.props;
		const { plants, loading } = this.state;

		if (loading) {
			return <div>Loading...</div>;
		}

		return (
			<div className='single_garden--select'>
				<input
					type='text'
					list='plantsList'
					placeholder='Search for a plant...'
					onChange={(e) => handleSingleGardenPlant(e, setSingleSearchPlantDropdown, this.state.plants)}
				/>
				<datalist id='plantsList'>
					{plants.map((plant) => (
						<option
							key={plant._id}
							value={plant.commonName ? plant.commonName : plant.latinName}
							data-image={plant.image}
							data-plantDescription={plant.plantDescription ? plant.plantDescription : ''}
							data-visible={plant.visible}
						>
							{plant.commonName ? plant.commonName : plant.latinName}
						</option>
					))}
				</datalist>
			</div>
		);
	}
}

export default SelectPlant;
