import React, { useState, useEffect, useHistory } from 'react';
import { Link } from 'react-router-dom';

import image_placeholder from './../assets/img/placeholder.jpg';

import Header from './../inc/Header';
import Banner from './../inc/Banner';
import Footer from './../inc/Footer';

function Home() {
	const [pendingPlants, setPendingPlants] = useState([]);
	const [resolvedPlants, setResolvedPlants] = useState([]);

	useEffect(() => {
		// Pending
		fetch('https://sph-v2-backend-22066.nodechef.com/api/expertChatList?status=Pending', {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
				setPendingPlants(data);
			})
			.catch((error) => console.error(error));
		// Pending

		// Resolved
		fetch('https://sph-v2-backend-22066.nodechef.com/api/expertChatList?status=Resolved', {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: 'Bearer ICg2rNHkTjNX1CVXqtvmUKeTl8VNfZhJeq0uQ4mHc29qLUwr6oLQ6LVRVFxeIxiU',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setResolvedPlants(data.data.reverse());
			})
			.catch((error) => console.error(error));
		// Resolved
	}, []);

	const changeType = () => {
		console.log('changeType');
	};

	return (
		<>
			<Header />

			<Banner type='home' />

			<div className='container'>
				<div className='row g-5 mb-5'>
					<div className='col-12'>
						<div className='border--title'>Pending Identification</div>
					</div>
				</div>

				<div className='row g-5 mb-5'>
					{pendingPlants && pendingPlants.data && Array.isArray(pendingPlants.data)
						? pendingPlants.data.map((plant) => {
								// if (plant.type !== 1) return;
								return (
									<div className='col-3' key={plant._id}>
										<div className='garden'>
											<div className='garden--type d-flex gap-2'>
												<div>{plant_type_txt(plant.type)}</div>
												<div role='button' className='cursor-pointer' onClick={changeType}>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='16'
														height='16'
														fill='currentColor'
														className='bi bi-pencil'
														viewBox='0 0 16 16'>
														<path d='M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z' />
													</svg>
												</div>
											</div>
											<div className='garden--image'>
												<img
													src={plant.image ? plant.image : image_placeholder}
													alt={plant._id}
												/>
											</div>
											<div className='garden--content'>
												<div className='garden--title'>{plant.title}</div>
												<div className='garden--datetime'>
													{convertStringIntoDate(plant.createdAt)}
												</div>
												<div className='garden--buttons'>
													{/* <Link to={`/single/${plant._id}`} state={{ plant: plant }}> */}
													{/* <Link to={`/single/${plant._id}`}> */}
													<Link to={`/single/${plant.user_id}/${plant._id}`}>
														<button className='garden--button'>
															{plant.type === 2 ? 'Identify Now' : 'View Now'}
														</button>
													</Link>
												</div>
											</div>
										</div>
									</div>
								);
						  })
						: ''}
				</div>

				<div className='row g-5 mb-5'>
					<div className='col-12'>
						<div className='border--title'>Resolved Identification</div>
					</div>
				</div>

				<div className='row g-5 mb-5'>
					{resolvedPlants && Array.isArray(resolvedPlants)
						? resolvedPlants.map((plant) => {
								// if (plant.type !== 1) return;
								return (
									<div className='col-3' key={plant._id}>
										<div className='garden'>
											<div className='garden--type'>{plant_type_txt(plant.type)}</div>
											<div className='garden--image'>
												{plant.image && plant.image !== 'string' ? (
													<img src={plant.image} alt={plant.title} />
												) : (
													<img src={image_placeholder} alt={plant.title} />
												)}
											</div>
											<div className='garden--content'>
												<div className='garden--title'>{plant.title}</div>
												<div className='garden--datetime'>
													{convertStringIntoDate(plant.createdAt)}
												</div>
												<div className='garden--buttons'>
													{/* <Link to={`/single/${plant._id}`} state={{ plant: plant }}> */}
													<Link to={`/single/${plant.user_id}/${plant._id}`}>
														<button className='garden--button'>{'View Now'}</button>
													</Link>
												</div>
											</div>
										</div>
									</div>
								);
						  })
						: ''}
				</div>
			</div>

			<Footer />
		</>
	);
}

export default Home;

function convertStringIntoDate(dateString) {
	const dateObj = new Date(dateString);

	// Convert to PST timezone
	const pstDateObj = new Date(dateObj.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));

	const options = {
		year: 'numeric',
		month: 'long',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	};

	// Format the date
	const formattedDate = pstDateObj.toLocaleString('en-US', options);

	// Add suffix for the day
	const dayWithSuffix = formattedDate.replace(/(\d+)(st|nd|rd|th)/, (_, day) => `${day}${getDaySuffix(day)}`);

	return dayWithSuffix;
}

// Helper function to get day suffix
function getDaySuffix(day) {
	if (day % 10 === 1 && day !== 11) {
		return 'st';
	} else if (day % 10 === 2 && day !== 12) {
		return 'nd';
	} else if (day % 10 === 3 && day !== 13) {
		return 'rd';
	} else {
		return 'th';
	}
}

function plant_type_txt(type) {
	const type_1 = 'General Care';
	const type_2 = 'Identify Plant';
	const type_3 = 'Pests / Diseases';

	if (type === 1) {
		return type_1;
	}
	if (type === 2) {
		return type_2;
	}
	if (type === 3) {
		return type_3;
	}
}
