import banner from './../assets/img/banner.jpg';
import { useEffect, useState } from 'react';

import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import BackArrow from './../assets/backArrow.png';

function Banner({ type, plant }) {
	// const [effectCount, seEffectCount] = useState(0);
	const [firebase_user] = useAuthState(auth);
	const [userName, setUserName] = useState('');
	// const [originID, setOriginID] = useState("");

	// let letEffectCount = effectCount;

	useEffect(() => {
		// letEffectCount++;
		// seEffectCount(letEffectCount);
		setUserName(firebase_user ? (firebase_user.displayName ? firebase_user.displayName : firebase_user.email) : '');
		// setOriginID(35865);
	}, [firebase_user]);

	return (
		<div className='banner' style={{ backgroundImage: `url(${banner})` }}>
			<div className='container m-auto'>
				<div className='row'>
					<div className='col-12'>
						<div className='banner--title'>
							{type === 'home' && (
								<p>
									Hi Welcome, <span>{userName}</span>
								</p>
							)}
							{type === 'chat' && (
								<div style={{ position: 'relative' }}>
									<div style={{ position: 'absolute' }}>
										<Link to='/dashboard' replace className='banner--button'>
											<img
												src={BackArrow}
												style={{ height: 'auto', width: '1.5rem', margin: '0 .5rem 0 0' }}
												alt='back arrow'
											/>
											Dashboard
										</Link>
									</div>
									<div className='text-center'>
										{plant_type_txt(plant.type)} <span>{plant.name}</span>
									</div>
								</div>
							)}
						</div>
						{/*
              <div className="banner--origin">Origin ID: {originID}</div>
              */}
					</div>
				</div>
			</div>
		</div>
	);
}

const plant_type_txt = (type) => {
	if (type === 1) return 'General Care';
	if (type === 2) return 'Identify Plant';
	if (type === 3) return 'Pests / Diseases';
};

export default Banner;
